

.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-section {
  margin: 10px 0;
}

.footer-section h4 {
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-icons li {
  display: flex;
  align-items: center;
}

.social-icons li svg {
  margin-right: 8px; /* Space between the icon and text */
}

.footer-section2 {
  background-color: #605555;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: left;
  }

  .footer-section {
    margin: 0 20px;
    flex: 1 1 200px;
  }

  .footer-section2 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer-section ul li a {
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .footer-section {
    margin: 10px 0;
  }
  
  .footer-section ul li a {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}







/*whatsapp and call logo css*/


.logo-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  animation:bounce 4s infinite alternate; /* Circular animation */


}



.logo {
  width: 20px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  transition: transform 4s ease-in-out; /* Smooth transition */
}

.logo:hover {
  transform: scale(0.8); /* Reduce size on hover */
}


@keyframes bounce {
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(-20px); /* Adjust the bounce height as needed */
}
}






.logo-conta{

  position: fixed;
  bottom: 20px;
  right: 20px;
  animation:bounce 4s infinite alternate; /* Circular animation */


}



.logo {
  width: 50px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  transition: transform 4s ease-in-out; /* Smooth transition */
}

.logo:hover {
  transform: scale(0.8); /* Reduce size on hover */
}


@keyframes bounce {
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(-20px); /* Adjust the bounce height as needed */
}

}