.about-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: navy;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
}


.main {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.about {
    height: 25rem;
    width: 100%;
    max-width: 25rem;
    margin-left: 6rem;
    margin-bottom: 100px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    padding: 0 10px;
}

.item {
    padding: 0;
    margin: 0;
}

.item img {
    width: 100%;
    height: auto;
    display: block;
}

.about-info {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
}

.about-info p {
    text-align: left;
    font-size: 1.1rem;
    margin-top: 20px;
    font-family: serif;
}

@media (max-width: 768px) {
    .main {
        width: 100%;
        display: flex;
        flex-direction: column; 
        align-items: center;
    }
    .about {
        margin-left: 0;
        height: auto;
        max-width: 100%;
    }

    .grid {
        grid-template-columns: 1fr;
    }

    .about-info {
        width: 100%;
        padding: 0 10px;
    }
}

@media (max-width: 480px) {
    .about-info p {
        font-size: 0.9rem;
    }
}
