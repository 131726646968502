.happy-clients-container1 {
    padding: 20px;
    text-align: center;
  }
  
  .heading1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: navy;
  }
  
  .cards-container1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .card1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
  }
  
  .client-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .client-info {
    text-align: center;
  }
  
  .client-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .client-rating {
    color: #ffc107;
    margin-bottom: 10px;
  }
  
  .client-description {
    font-size: 1rem;
    color: #6c757d;
  }
  
  @media (min-width: 768px) {
    .card1 {
      width: 45%;
    }
  }
  
  @media (min-width: 992px) {
    .card1 {
      width: 30%;
    }
  }
  