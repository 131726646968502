.portfolio-page {
    text-align: center;
    padding: 20px;
}

.portfolio-header {
    position: relative;
    text-align: center;
}

.header-image {
    width: 100%;   
    height: auto;
}

.header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.section-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0;
}

.section-item {
    margin: 10px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.section-item:hover {
    background-color: #f0f0f0;
}

/* .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 20px;
}

.image-item {
    position: relative;
    overflow: hidden;
} */

.image-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.image-item:hover img {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .header-text {
        font-size: 1.5em;
    }

    .section-item {
        font-size: 0.9em;
        padding: 5px 10px;
    }
}

@media (max-width: 480px) {
    .header-text {
        font-size: 1.2em;
    }

    .section-item {
        font-size: 0.8em;
        padding: 5px 10px;
    }
}