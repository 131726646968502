/* crousel- css */

.slider-container {
  max-width: 1250px;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  height: auto;
}


/* OurStory.css */
.our-story-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.our-story-container .head1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.our-story-container .para1 {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
}

/* Mobile Styles */

@media (max-width: 600px) {
  .our-story-container .head1 {
    font-size: 2rem;
  }

  .our-story-container .para1 {
    font-size: 1rem;
  }
}

/* Tablet Styles */
@media (min-width: 601px) and (max-width: 900px) {
  .our-story-container .head1 {
    font-size: 2.2rem;
  }

  .our-story-container .para1 {
    font-size: 1.1rem;
  }
}

/* Laptop and Desktop Styles */
@media (min-width: 901px) {
  .our-story-container .head1 {
    font-size: 2.5rem;
  }

  .our-story-container .para1 {
    font-size: 1.2rem;
  }
}


/* ----------------------- General styles */
body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.responsive-page {
  padding: 20px;
  text-align: center;
}

.heading {
  font-size: 2em;
  margin-bottom: 20px;
}

/* Image grid styles */
.image-grid {
  /* display: grid; */

  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-gap: 0.2rem;
  width: 100%;
  padding: 0 10px;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-container:hover img {
  transform: scale(1.2);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}

.text {
  font-size: 1.5em;
}

/* Button styles */
.center-button {
  display: inline-block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.center-button:hover {
  background-color: darkred;
}

/* Responsive styles */

@media (max-width: 1024px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5em;
  }

  .text {
    font-size: 1.2em;
  }

  .center-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.2em;
  }

  .text {
    font-size: 1em;
  }

  .center-button {
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .image-grid {
    grid-template-columns: 1fr;
  }
}

/* -------------------------End General styles */

/* ------------------- icon css start */

.home-interior-design {
  padding: 20px;
  text-align: center;
}

.heading {
  font-size: 2em;
  margin-bottom: 20px;
}

/* Services grid styles */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  justify-items: center;
  align-items: center;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.service-item:hover {
  transform: scale(1.1);
}

.icon {
  font-size: 2em;
  margin-bottom: 10px;
}

.name {
  font-size: 1em;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    font-size: 1.5em;
  }

  .name {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    font-size: 1.2em;
  }

  .name {
    font-size: 0.8em;
  }
}

/* ------------------- icon css end */
/*--------------------start CardLayout.css */

.main-heading {
  text-align: center;
  font-size: 2.5em;
  margin: 20px 0;
}


.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  text-align: center;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.5em;
  margin: 10px 0;
}

.card-description {
  padding: 0 10px 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .card-title {
    font-size: 1.2em;
  }

  .card-description {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .card-title {
    font-size: 1em;
  }

  .card-description {
    font-size: 0.8em;
  }
}
/*--------------------end CardLayout.css */

/* -------------------start FAQ.css */

.faq-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.main-heading {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.faq-heading {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

/* FAQ styles */
.faq-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.faq-item:hover {
  background-color: #f9f9f9;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  margin-top: 10px;
  display: none;
}

.faq-item.active .faq-answer {
  display: block;
}

/* Responsive styles */
@media (max-width: 768px) {
  .main-heading {
    font-size: 2em;
  }

  .faq-heading {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 1.5em;
  }

  .faq-heading {
    font-size: 1.2em;
  }
}
/* -------------------end FAQ.css */

/* ------------------- start css form---------- */

.professional-expertise-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-image: url('https://t4.ftcdn.net/jpg/05/51/69/95/360_F_551699573_1wjaMGnizF5QeorJJIgw5eRtmq5nQnzz.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.form-container {
  background-color: #fff9db; /* Light yellow background */
  padding: 20px;
  flex: 1;
  max-width: 500px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container label {
  margin-top: 10px;
  font-weight: bold;
}

.form-container input,
.form-container textarea,
.form-container select {
  margin-top: 5px;
  padding: 10px;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-container button {
  margin-top: 20px;
  padding: 10px;
  font-size: 1em;
  background-color: #f7931e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.checkbox-container input {
  margin-right: 10px;
}

.success-message {
  margin-top: 20px;
  color: green;
}

.info-container {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  margin: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.info-container h1,
.info-container h2 {
  margin: 10px 0;
}

@media (max-width: 1024px) {
  .professional-expertise-container {
    flex-direction: column;
    justify-content: center;
  }

  .form-container {
    max-width: 100%;
    margin: 10px 0;
  }

  .info-container {
    max-width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .form-container {
    padding: 15px;
  }

  .info-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 10px;
  }

  .info-container {
    padding: 10px;
  }

  .info-container h1 {
    font-size: 1.5em;
  }

  .info-container h2 {
    font-size: 1em;
  }
}



/* ---------------------end css form------------- */
/* ---------------------statrt reviews section------ */

.reviews-section {
  padding: 20px;
  text-align: center;
}

.reviews-heading {
  font-size: 2em;
  margin-bottom: 20px;
}

/* Reviews scroller styles */
.reviews-scroller {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.review-card {
  min-width: 250px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.review-card:hover {
  transform: scale(1.05);
}

.review-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.review-name {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.review-message {
  font-size: 1em;
  margin-bottom: 10px;
}

.review-stars {
  font-size: 1.2em;
  color: #ff9800;
}

/* Responsive styles */
@media (max-width: 768px) {
  .reviews-heading {
    font-size: 1.5em;
  }

  .review-card {
    min-width: 200px;
    padding: 15px;
  }

  .review-image {
    width: 80px;
    height: 80px;
  }

  .review-name {
    font-size: 1em;
  }

  .review-message {
    font-size: 0.9em;
  }

  .review-stars {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .reviews-heading {
    font-size: 1.2em;
  }

  .review-card {
    min-width: 180px;
    padding: 10px;
  }

  .review-image {
    width: 70px;
    height: 70px;
  }

  .review-name {
    font-size: 0.9em;
  }

  .review-message {
    font-size: 0.8em;
  }

  .review-stars {
    font-size: 0.9em;
  }
}


/* -----------------------end reviews section------- */
/* -------------------------start address section------- */

.home {
  padding: 20px;
}

.shop-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.carousel-container,
.address-container {
  width: 100%;
  max-width: 600px;
}

.address-container {
  background-color: #fff8dc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.address-container h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.address-container p {
  margin: 10px 0;
  font-size: 1em;
}

.direction-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
}

.direction-button:hover {
  background-color: #45a049;
}

/* Responsive styles */
@media (min-width: 769px) {
  .shop-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .carousel-container,
  .address-container {
    max-width: 45%;
  }

  .address-container {
    margin-top: 0;
  }
}

/* ---------------------------end address section--------- */