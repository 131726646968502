/* src/components/Services.css */

.services-container {
    padding: 20px;
    text-align: center;
  }
  
  .service-section {
    margin-bottom: 40px;
  }
  
  .service-section h2 {
    margin-bottom: 20px;
  }
  
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .images img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .guarantee {
    margin-top: 40px;
  }
  
  .guarantee h2 {
    margin-bottom: 10px;
  }
  
  .guarantee p {
    font-size: 1.2em;
  }
  
  @media (min-width: 768px) {
    .images img {
      width: 30%;
    }
  }
  
  @media (max-width: 767px) {
    .images img {
      width: 100%;
    }
  }
  