/* src/components/Contact.css */

.container {
    padding: 20px;
  }
  
  .contact-form {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  button[type="submit"] {
    background-color: #f8843d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  
  button[type="submit"]:hover {
    background-color: #a84809;
  }
  
  .contact-card,
  .address-card,
  .email-card {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 600px;
    text-align: center;
  }
  
  .contact-logo,
  .address-logo,
  .email-logo {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .map-container {
    margin: 20px auto;
    max-width: 600px;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .contact-form,
    .contact-card,
    .address-card,
    .email-card {
      padding: 15px;
      margin: 10px;
    }
  
    .map-container iframe {
      width: 100%;
      height: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .contact-form,
    .contact-card,
    .address-card,
    .email-card {
      padding: 10px;
    }
  
    button[type="submit"] {
      width: 100%;
    }
  
    .map-container iframe {
      height: 200px;
    }
  }
  